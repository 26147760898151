import moment from 'moment'

export function DateFormat(date: string) {
  return moment(date).format('dddd, MMMM Do, YYYY h:mm:ss A')
}

export function DateTimeFormat(date: string) {
  return date.split(/[?#]/)[0]
}

export function DateDifference(date1: any, date2: any) {
  const billdate = new Date(moment(new Date()).format('YYYY-MM-DD'))
  const startdate = new Date(moment(date2).format('YYYY-MM-DD'))
  const diff = billdate.getTime() - startdate.getTime()
  const msInDay = 1000 * 60 * 60 * 24
  const days = Math.floor(diff / msInDay)
  let result = ''

  if (diff > 0) {
    result = `Overdue from ${Math.abs(days)} days`
  } else {
    result = `Due in ${Math.abs(days)} days`
  }

  return result
}

export function NumbertoWord(n: any) {
  if (n < 0) return false
  let single_digit = ['', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine']
  let double_digit = [
    'Ten',
    'Eleven',
    'Twelve',
    'Thirteen',
    'Fourteen',
    'Fifteen',
    'Sixteen',
    'Seventeen',
    'Eighteen',
    'Nineteen',
  ]
  let below_hundred = ['Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety']
  if (n === 0) return 'Zero'
  function translate(n: any) {
    let word = ''
    if (n < 10) {
      word = single_digit[n] + ' '
    } else if (n < 20) {
      word = double_digit[n - 10] + ' '
    } else if (n < 100) {
      let rem = translate(n % 10)
      word = below_hundred[(n - (n % 10)) / 10 - 2] + ' ' + rem
    } else if (n < 1000) {
      word = single_digit[Math.trunc(n / 100)] + ' Hundred ' + translate(n % 100)
    } else if (n < 1000000) {
      word = translate(n / 1000).trim() + ' Thousand ' + translate(n % 1000)
    } else if (n < 1000000000) {
      word = translate(n / 1000000).trim() + ' Million ' + translate(n % 1000000)
    } else {
      word = translate(n / 1000000000).trim() + ' Billion ' + translate(n % 1000000000)
    }
    return word
  }
  let result = translate(n)
  return result.trim()
}

export function AmountFormatter(n?: number) {
  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
  return formatter.format(Number(n))
}

export function _addDaysInDate(datetoadd: string = '', daytoadd: any = 0) {
  const date = new Date(datetoadd)

  const numberOfDaysToAdd = parseInt(daytoadd)
  // console.log('add date', datetoadd, date, daytoadd, numberOfDaysToAdd)
  return date.setDate(date.getDate() + numberOfDaysToAdd)
}

export function _dayCounter(datetoadd: string = '', daytoadd: any = 0) {
  const currentDate: any = new Date()
  const toDate: any = new Date(_addDaysInDate(datetoadd, daytoadd))

  const differenceInMilliseconds = toDate - currentDate

  const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24)

  return differenceInDays
}

export const chargesAndFees = [
  "Excess KM's charge",
  "Bond 1 Low",
  "Bond 2 Middle",
  "Bond 3 High",
  "Bond 4 High Risk",
  "Accident Excess",
  "Accident Age Excess 1",
  "Accident Age Excess 2",
  "Cleaning Fee (Mild)",
  "Cleaning Fee (Severe)",
  "Vehicle recovery fee (Metro)",
  "Vehicle Recovery Fee (Rural)",
  "Early Contract Termination Fee",
  "Rubbish Removal $100.00",
  "Signage Option 1 Single Cab (no tray)",
  "Signage Option 2 Dual Cab (no tray)",
  "Signage Option 3 Single Cab (with Tray)",
  "Signage Option 4 Dual Cab (with Tray)",
  "Signage Option 5 Dual Cab (tub)",
  "Admin Charge",
  "PPSR Charge",
  "PMSI Charge",
  "Tolls admin fee",
  "Infringement fee",
  "Late payment fee",
  "Lost Key",
  "Lost Key Toolbox",
  "Lost Jack / Toolkit",
  "Lost/ Damaged Fuel Filler Cap",
  "Lost Ladder Rack",
  "Damaged side Mirror (repair)",
  "Damaged side Mirror (replacement)",
  "Toolbox replacement",
  "Badge replacement",
  "Number plate replacement",
  "Spare wheel",
  "Rubber mats",
  "Headlight Replacement",
  "Front Bumper Replacement",
  "Tail light protector replacement",
  "Tail light replacement",
  "Wipers",
  "Handbrake Adjustment",
  "Tyre Rotation",
  "Fuel Filter",
  "Brake Pads",
  "Disc rotors",
  "Tyre Puncture",
  "Light Globe",
  "Stone Chip",
  "Wheel Alignment"
];

export function calculateEndDate(deliveryDateTime: string | undefined, packageType: string | undefined, duration: string | undefined) {
  // Check for valid deliveryDateTime
  if (!deliveryDateTime) {
    return 'No date';
  }

  const deliveryDate = new Date(deliveryDateTime);

  const numericDuration = parseInt(duration || '0', 10);
  const normalizedPackageType = packageType?.toLowerCase();
  if (normalizedPackageType === 'rent to own') {
    deliveryDate.setFullYear(deliveryDate.getFullYear() + numericDuration);
  } else {
    deliveryDate.setMonth(deliveryDate.getMonth() + 3);
  }
  return deliveryDate.toISOString().split('T')[0];
}

export function calculateMinEndDate(deliveryDateTime: string | undefined, packageType: string | undefined, duration: string | undefined | number) {
  try {
    const deliveryDate = moment(deliveryDateTime, moment.ISO_8601, true);
    if (!deliveryDate.isValid()) {
      throw new Error("No deliveryDateTime");
    }

    let minEndDate;
    // Check for Rent To Own package
    if (packageType === 'Rent To Own') {
      if (duration === '4' || duration === 4) {  // Add 12 months (1 year) for 4-year duration
        minEndDate = deliveryDate.add(12, 'months');
      } else if (duration === '5' || duration === 5) { // Add 24 months (2 years) for 5-year duration
        minEndDate = deliveryDate.add(24, 'months');
      } else {
        throw new Error("No duration for Rent To Own");
      }
    } else {
      minEndDate = deliveryDate.add(3, 'months'); // For other packages, add 3 months
    }
    const today = moment(); // Get today's date
    const daysRemaining = minEndDate.diff(today, 'days');

    return {
      minEndDate: minEndDate.format('YYYY-MM-DD'),
      daysRemaining: daysRemaining >= 0 ? daysRemaining : 0 // If the difference is negative, return 0
    };
  } catch (error) {
    return {
      minEndDate: "-",
      daysRemaining: 0
    };
  }
}