import React, { useEffect, useState } from 'react'
import { Form, Row, Col } from 'react-bootstrap'

interface IMyProps {
  packages: any 
  handleSelectPackage: (updatedPkg: object) => void
}

const Step5: React.FC<IMyProps> = (props: IMyProps) => {

  const { packages: newPackage, handleSelectPackage } = props
  const [deliveryDateTime, setDeliveryDateTime] = useState<string>('')
  const [country, setCountry] = useState<string>('')
  const [districtCity, setDistrictCity] = useState<string>('')
  const [addressLine1, setAddressLine1] = useState<string>('')
  const [addressLine2, setAddressLine2] = useState<string>('')
  const [stateProvince, setStateProvince] = useState<string>('')
  const [postalCode, setPostalCode] = useState<string>('')

  useEffect(() => {
    if (newPackage?.deliveryDetails) {
      const {
        deliveryDateTime: savedDateTime,
        country: savedCountry,
        district_city: savedDistrictCity,
        address_line_1: savedAddressLine1,
        address_line_2: savedAddressLine2,
        state_province: savedStateProvince,
        postal_code: savedPostalCode,
      } = newPackage.deliveryDetails

      setDeliveryDateTime(savedDateTime || '')
      setCountry(savedCountry || '')
      setDistrictCity(savedDistrictCity || '')
      setAddressLine1(savedAddressLine1 || '')
      setAddressLine2(savedAddressLine2 || '')
      setStateProvince(savedStateProvince || '')
      setPostalCode(savedPostalCode || '')
    }
  }, [newPackage])

  // Handle form updates
  useEffect(() => {
    if (
      deliveryDateTime ||
      country ||
      districtCity ||
      addressLine1 ||
      addressLine2 ||
      stateProvince ||
      postalCode
    ) {
      handleSelectPackage({
        ...newPackage,
        deliveryDetails: {
          deliveryDateTime,
          country,
          district_city: districtCity,
          address_line_1: addressLine1,
          address_line_2: addressLine2,
          state_province: stateProvince,
          postal_code: postalCode,
        },
      })
    }
  }, [deliveryDateTime, country, districtCity, addressLine1, addressLine2, stateProvince, postalCode])

  return (
    <div data-kt-stepper-element='content'>
      <div className="w-100">
        {/* Heading */}
        <h1 className="fw-bold text-dark mb-4 text-center">Delivery Details</h1>

        {/* Form */}
        <Form>
          <Row className="mb-3">
            <Col md={12}>
              <Form.Group>
                <Form.Label className="required fw-bold text-dark">Delivery Date/Time</Form.Label>
                <Form.Control
                  type="datetime-local"
                  value={deliveryDateTime}
                  onChange={(e) => setDeliveryDateTime(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={6}>
              <Form.Group>
                <Form.Label className="required fw-bold text-dark">Country</Form.Label>
                <Form.Control
                  type="text"
                  value={country}
                  onChange={(e) => setCountry(e.target.value)}
                  placeholder="Enter country"
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label className="required fw-bold text-dark">State/Province</Form.Label>
                <Form.Control
                  type="text"
                  value={stateProvince}
                  onChange={(e) => setStateProvince(e.target.value)}
                  placeholder="Enter state or province"
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={6}>
              <Form.Group>
                <Form.Label className="required fw-bold text-dark">Address Line 1</Form.Label>
                <Form.Control
                  type="text"
                  value={addressLine1}
                  onChange={(e) => setAddressLine1(e.target.value)}
                  placeholder="Enter address line 1"
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label className="fw-bold text-dark">Address Line 2</Form.Label>
                <Form.Control
                  type="text"
                  value={addressLine2}
                  onChange={(e) => setAddressLine2(e.target.value)}
                  placeholder="Enter address line 2 (optional)"
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={6}>
              <Form.Group>
                <Form.Label className="fw-bold text-dark">District/City</Form.Label>
                <Form.Control
                  type="text"
                  value={districtCity}
                  onChange={(e) => setDistrictCity(e.target.value)}
                  placeholder="Enter district or city"
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label className="required fw-bold text-dark">Postal Code</Form.Label>
                <Form.Control
                  type="number"
                  value={postalCode}
                  onChange={(e) => setPostalCode(e.target.value)}
                  placeholder="Enter postal code"
                />
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  )
}

export { Step5 }
