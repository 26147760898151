import { Dispatch, SetStateAction, createContext, useState, ReactNode } from 'react';

// Define the Packages type
export type Packages = {
  packageId: string;
  packageType: string;
  planType: string;
  planCost: string;
  vehicleType: string;
  flexiRentQty: number;
  total_KMs: string;
  contracted_services: string;
  tyres: number;
  vehicle: {
    body_type: string;
    color: string;
    fuel_type: string;
    Make: string;
    Model: string;
    vehicle_name: string,
    registration: string;
    transmission: string;
    vin: string;
    year: string;
    _id: string;
  };
  accessories: Array<any>;
  signageOption: string;
  deliveryDetails: {
    deliveryDateTime: string,
    country: string,
    district_city: string,
    address_line_1: string,
    address_line_2: string,
    state_province: string,
    postal_code: string,
  },
  _id: any,
  approve: boolean,
  zoho_job_id?: string,
  completed: boolean;
};

// Define the PackageContextInterface
export interface PackageContextInterface {
  packages: Packages[];
  setPackage: Dispatch<SetStateAction<Packages[]>>;
}

// Default package data for context initialization
const defaultPackageData: PackageContextInterface = {
  packages: [],
  setPackage: () => { },
};

// Create the context
export const PackageContext = createContext<PackageContextInterface>(defaultPackageData);

type PackageProviderProps = {
  children: ReactNode;
};

// PackageProvider component to provide context values
export default function PackageProvider({ children }: PackageProviderProps) {
  const [packages, setPackage] = useState<Packages[]>([]);

  return (
    <PackageContext.Provider value={{ packages, setPackage }}>
      {children}
    </PackageContext.Provider>
  );
}
