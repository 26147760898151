/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext, useLayoutEffect } from 'react';
import { toAbsoluteUrl } from '../../../../helpers';
import { PackageContext } from '../../../../../app/modules/subscriptions/context/SubscriptionContext';
import { NumbertoWord } from '../../../../helpers/UtilityHelpers';
import moment from 'moment';

interface IMyProps {
  packages: Pkg
}

interface Pkg {
  packageId: string;
  packageType: string;
  planType: string;
  planCost: string;
  vehicleType: string;
  flexiRentQty: number;
  total_KMs: string;
  contracted_services: string;
  tyres: number;
  vehicle: {
    body_type: string;
    color: string;
    fuel_type: string;
    Make: string;
    Model: string;
    vehicle_name: string;
    registration: string;
    transmission: string;
    vin: string;
    year: string;
    _id: string;
  };
  accessories: Array<any>,
  signageOption: string,
  deliveryDetails: {
    deliveryDateTime: string,
    country: string,
    district_city: string,
    address_line_1: string,
    address_line_2: string,
    state_province: string,
    postal_code: string,
    // location: '',
  },
  approve: boolean,
  completed: boolean;
};

const Step6: React.FC<IMyProps> = (props: IMyProps) => {

  const { packages: newPackage } = props

  useLayoutEffect(() => {
    const timer = setTimeout(() => {
      console.log('This will run after 1 second!');
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <div data-kt-stepper-element="content">
        <div className="w-100">
          {/* Heading */}
          <h1 className="fw-bold text-dark mb-3 text-center">Acknowledge!</h1>

          {/* Description */}
          <div className="text-muted fw-semibold fs-4 text-center">
            Acknowledge package information before submitting.
          </div>

          {/* Package Details */}
          <div className="mt-6 px-12">
            {/* Package Type */}
            <div className="d-flex fv-row">
              <div className="fw-semibold text-gray-600 min-w-100px">Package Type</div>
              <div className="fw-bold text-gray-800 ms-8">{newPackage?.packageType}</div>
            </div>
            <div className="separator separator-dashed my-5"></div>

            {/* Plan Type */}
            {newPackage?.planType && (
              <div className="d-flex fv-row">
                <div className="fw-semibold text-gray-600 min-w-100px">Plan Type</div>
                <div className="fw-bold text-gray-800 ms-8">
                  {NumbertoWord(newPackage?.planType)} years plan
                </div>
              </div>
            )}
            <div className="separator separator-dashed my-5"></div>

            {/* Vehicle Name */}
            <div className="d-flex fv-row">
              <div className="fw-semibold text-gray-600 min-w-100px">Vehicle</div>
              <div className="fw-bold text-gray-800 ms-8">{newPackage?.vehicle.vehicle_name}</div>
            </div>
            <div className="separator separator-dashed my-5"></div>

            {/* Accessories */}
            <div className='d-flex fv-row'>
              <div className='col-12'>
                <h5 className='fw-bold text-gray-600 min-w-100px'>Accessories</h5>
                <ul className='list-group'>
                  {newPackage?.accessories?.map((accessory) => (
                    <li key={accessory._id} className='list-group-item d-flex justify-content-between align-items-center'>
                      {accessory.name}
                      <span className='badge bg-primary rounded-pill'>${accessory.price}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="separator separator-dashed my-5"></div>

            {/* Signage Option */}
            <div className="d-flex fv-row">
              <div className="fw-semibold text-gray-600 min-w-100px">Signage Option</div>
              <div className="fw-bold text-gray-800 ms-8">{newPackage?.signageOption}</div>
            </div>
            <div className="separator separator-dashed my-5"></div>

            {/* Delivery Details */}
            <div className="d-flex fv-row">
              <div className="fw-semibold text-gray-600 min-w-100px">Delivery Date/Time</div>
              <div className="fw-bold text-gray-800 ms-8">
                {newPackage?.deliveryDetails?.deliveryDateTime ?
                  moment(newPackage?.deliveryDetails?.deliveryDateTime).format('MMMM Do, YYYY')
                  : 'Not specified'}
              </div>
            </div>
            <div className="separator separator-dashed my-5"></div>

            <div className="d-flex fv-row">
              <div className="fw-semibold text-gray-600 min-w-100px">Delivery Address</div>
              <div className="fw-bold text-gray-800 ms-8">
                {newPackage?.deliveryDetails?.country || 'Not specified'} {" "}
                {newPackage?.deliveryDetails?.address_line_1 || 'Not specified'}
              </div>
            </div>
            <div className="separator separator-dashed my-5"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export { Step6 };
