import { useState, useEffect } from 'react'
import { getPartsAndAccessories } from '../../../../../app/modules/vechicles/partsAndAccessories/partsAndAccessories-list/core/_requests'
import { Packages } from '../../../../../app/modules/subscriptions/context/SubscriptionContext'
import { IAccessories } from '../../../../../app/modules/subscriptions/create/ISubscription'

interface IMyProps {
  packages: Packages
  handleSelectPackage: (updatedPkg: object) => void
}

const Step3: React.FC<IMyProps> = (props: IMyProps) => {
  const { packages: newPackage, handleSelectPackage } = props

  const [VehiclesAccessories, setVehiclesAccessories] = useState<IAccessories[]>([])
  const [accessoryCounts, setAccessoryCounts] = useState<{ [key: string]: number }>({})
  const [selectedAccessories, setSelectedAccessories] = useState<IAccessories[]>([])
  const [paymentMethod, setPaymentMethod] = useState<string>('')

  const defaultAccessories = ['Ladder Rack', 'Tow Bar', '1 x tool box']

  useEffect(() => {
    const fetchAccessories = async () => {
      const result = await getPartsAndAccessories(``) as any
      const fetchedAccessories = result.data.docs

      // Filter out the default accessories
      const filteredAccessories = fetchedAccessories.filter((acc: any) =>
        defaultAccessories.includes(acc.name)
      )

      setVehiclesAccessories(fetchedAccessories)
      setSelectedAccessories(filteredAccessories)

      // Initialize counts for the default accessories
      const initialCounts: { [key: string]: number } = {}
      filteredAccessories.forEach((acc: any) => {
        initialCounts[acc._id] = 1
      })
      setAccessoryCounts(initialCounts)
    }

    fetchAccessories()
  }, [])

  // Populate accessories from newPackage if they exist....
  useEffect(() => {
    if (newPackage.accessories && newPackage.accessories.length > 0) {
      setSelectedAccessories(newPackage.accessories)

      // Initialize accessory counts based on what's in newPackage...
      const initialCounts: { [key: string]: number } = {}
      newPackage.accessories.forEach((acc: IAccessories) => {
        initialCounts[acc._id] = 1 // Default count to 1 for each accessory from newPackage...
      })
      setAccessoryCounts(initialCounts)

      // Remove already selected accessories from the VehiclesAccessories list...
      setVehiclesAccessories((prevAccessories) =>
        prevAccessories.filter(
          (acc) => !newPackage.accessories.some((selected) => selected._id === acc._id)
        )
      )
    }
  }, [newPackage])

  useEffect(() => {
    handlePackageAccessories()
  }, [selectedAccessories])

  const handleAccessorySelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedAccessory = VehiclesAccessories.find(
      (acc) => acc._id === event.target.value
    )
    if (selectedAccessory) {
      setSelectedAccessories([...selectedAccessories, selectedAccessory])
      setVehiclesAccessories(
        VehiclesAccessories.filter((acc) => acc._id !== selectedAccessory._id)
      )
      setAccessoryCounts({
        ...accessoryCounts,
        [selectedAccessory._id]: 1, // Initialize count to 1 when adding a new accessory....
      })
    }
  }

  const handleCountChange = (id: string, delta: number) => {
    setAccessoryCounts((prevCounts) => {
      const newCount = Math.max(0, (prevCounts[id] || 0) + delta)
      if (newCount === 0) {
        // Remove accessory if count is zero...
        const removedAccessory = selectedAccessories.find((acc) => acc._id === id)
        if (removedAccessory) {
          setVehiclesAccessories([...VehiclesAccessories, removedAccessory])
          setSelectedAccessories(selectedAccessories.filter((acc) => acc._id !== id))
        }
      }
      return {
        ...prevCounts,
        [id]: newCount,
      }
    })
  }

  const calculateTotalPrice = () => {
    return selectedAccessories.reduce((total, acc: any) => {
      const count = accessoryCounts[acc._id] || 0
      const isDefaultAccessory = defaultAccessories.includes(acc.name)

      // For default accessories: 
      // When count = 1 -> Price is 0
      // When count >= 2 -> Price starts from count 1
      let priceForThisAccessory = 0
      if (isDefaultAccessory) {
        if (count > 1) {
          priceForThisAccessory = acc.price * (count - 1) // Apply price starting from count 2
        }
      } else {
        // For non-default accessories, apply the price normally
        priceForThisAccessory = acc.price * count
      }

      return total + priceForThisAccessory
    }, 0)
  }

  const handlePaymentMethodChange = (method: string) => {
    setPaymentMethod(method)
  }

  const handlePackageAccessories = () => {
    handleSelectPackage({ ...newPackage, accessories: selectedAccessories })
  }

  return (
    <>
      {/*begin::Step 3 */}
      <div className='pb-5' data-kt-stepper-element='content'>
        <div className='w-100'>
          <div className='fv-row mb-4'>
            <label className='fs-5 fw-semibold mb-2'>Select and add other accessories</label>
            <select className='form-select form-select-lg' onChange={handleAccessorySelect}>
              <option value=''>Select an accessory</option>
              {VehiclesAccessories.map((accessory) => (
                <option key={accessory._id} value={accessory._id}>
                  {accessory.name} - ${accessory.price}
                </option>
              ))}
            </select>
          </div>

          <div className='fv-row'>
            <label className='d-flex align-items-center fs-5 fw-semibold mb-4'>
              <span className=''>Selected Accessories</span>
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title='Selected Accessories'
              ></i>
            </label>

            <div className='py-5'>
              <div data-kt-search-element='results' className={selectedAccessories.length > 0 ? '' : 'd-none'}>
                <div className='mh-300px scroll-y me-n5 pe-5'>
                  {/* {selectedAccessories.map((vData: any) => (
                    <div
                      key={vData._id}
                      className='d-flex align-items-center justify-content-between p-3 rounded-3 border border-primary mb-1'
                    >
                      <div className='fw-semibold'>
                        <span className='fs-6 text-gray-800 me-2'>{vData?.name}</span>
                        <span className='fs-5 text-primary'>
                          ${vData?.price * (accessoryCounts[vData._id] || 0)}
                        </span>
                      </div>
                      <div className='d-flex align-items-center'>
                        <button
                          type='button'
                          className='btn btn-sm btn-light-primary'
                          onClick={() => handleCountChange(vData._id, -1)}
                        >
                          -
                        </button>
                        <span className='mx-2'>{accessoryCounts[vData._id] || 0}</span>
                        <button
                          type='button'
                          className='btn btn-sm btn-light-primary'
                          onClick={() => handleCountChange(vData._id, 1)}
                        >
                          +
                        </button>
                      </div>
                    </div>
                  ))} */}
                  {selectedAccessories.map((vData: any) => {
                    const count = accessoryCounts[vData._id] || 0
                    const isDefaultAccessory = defaultAccessories.includes(vData.name)
                    let displayedPrice = 0

                    if (isDefaultAccessory) {
                      if (count > 1) {
                        displayedPrice = vData.price * (count - 1) // Show price starting from count 2
                      }
                    } else {
                      displayedPrice = vData.price * count
                    }

                    return (
                      <div
                        key={vData._id}
                        className='d-flex align-items-center justify-content-between p-3 rounded-3 border border-primary mb-1'
                      >
                        <div className='fw-semibold'>
                          <span className='fs-6 text-gray-800 me-2'>{vData?.name}</span>
                          <span className='fs-5 text-primary'>
                            ${displayedPrice}
                          </span>
                        </div>
                        <div className='d-flex align-items-center'>
                          <button
                            type='button'
                            className='btn btn-sm btn-light-primary'
                            onClick={() => handleCountChange(vData._id, -1)}
                          >
                            -
                          </button>
                          <span className='mx-2'>{count}</span>
                          <button
                            type='button'
                            className='btn btn-sm btn-light-primary'
                            onClick={() => handleCountChange(vData._id, 1)}
                          >
                            +
                          </button>
                        </div>
                      </div>
                    )
                  })}

                </div>
              </div>
            </div>

            {/* Total Price Section */}
            <div className='card bg-success text-white mb-4'>
              <div className='card-body'>
                <h5 className='card-title'>Total Price</h5>
                <h3 className='card-text'>
                  ${calculateTotalPrice().toFixed(2)}
                </h3>
              </div>
            </div>

            {/* Payment Method UI */}
            {/* <div className='mt-10'>
              <label className='fs-5 fw-semibold mb-2'>Payment Method</label>
              <div className='d-flex justify-content-between'>
                <button
                  className={`btn btn-outline-primary w-100 me-3 ${paymentMethod === 'Cash' ? 'active' : ''}`}
                  onClick={() => handlePaymentMethodChange('Cash')}
                >
                  Upfront
                </button>
                <button
                  className={`btn btn-outline-primary w-100 ${paymentMethod === 'E-Wallet' ? 'active' : ''}`}
                  onClick={() => handlePaymentMethodChange('E-Wallet')}
                >
                  Over 3 Months
                </button>
              </div>
            </div> */}

            {/* Print Bills Button */}
            {/* <div className='mt-4'>
              <button
                className='btn btn-primary w-100'
                onClick={() => console.log('Proceed to Payment')}
              >
                Print Bills
              </button>
            </div> */}
          </div>
        </div>
      </div>
      {/*end::Step 3 */}
    </>
  )
}

export { Step3 }
