import { useState, useEffect } from "react";
import { Packages } from "../../../../../app/modules/subscriptions/context/SubscriptionContext";

interface IMyProps {
    packages: Packages;
    handleSelectPackage: (updatedPkg: object) => void;
}

const Step4: React.FC<IMyProps> = (props: IMyProps) => {

    const { packages: newPackage, handleSelectPackage } = props;
    const [selectedSignage, setSelectedSignage] = useState<string>('');

    let { vehicle: { vehicle_name } } = newPackage;

    const vehicleName = vehicle_name;
    
    const isDualCab = vehicleName?.toLowerCase().includes("dual");

    const signageOptions = isDualCab
        ? [
            "Signage Option 2 Dual Cab (no tray)",
            "Signage Option 4 Dual Cab (with Tray)",
            "Signage Option 5 Dual Cab (tub)",
        ]
        : [
            "Signage Option 1 Single Cab (no tray)",
            "Signage Option 3 Single Cab (with Tray)",
        ];

    // Populate selected signage from newPackage if it exists
    useEffect(() => {
        if (newPackage?.signageOption) {
            setSelectedSignage(newPackage.signageOption);
        }
    }, [newPackage]);

    const handleSignageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedOption = event.target.value;
        setSelectedSignage(selectedOption);
        handleSelectPackage({ ...newPackage, signageOption: selectedOption });
    };

    return (
        <>
            {/*begin::Step 4 */}
            <div className="pb-5" data-kt-stepper-element="content">
                <div className="w-100">
                    {/*begin::Form Group */}
                    <div className="fv-row">
                        <label className="fs-6 fw-bolder text-dark mb-7 d-flex align-items-center fs-5 fw-semibold mb-4">
                            <span className="">Select Signage Option</span>
                        </label>

                        {signageOptions.map((option, index) => (
                            <label
                                key={index}
                                className="d-flex align-items-center justify-content-between cursor-pointer mb-6"
                            >
                                <span className="d-flex align-items-center me-2">
                                    <span className="symbol symbol-50px me-6">
                                        <span className="symbol-label bg-light-primary">
                                            <i className="fab fa-car text-primary fs-2x"></i>
                                        </span>
                                    </span>

                                    <span className="d-flex flex-column">
                                        <span className="fw-bolder fs-6">{option}</span>
                                        <span className="fs-7 text-muted">
                                            {option.includes("Dual") ? "For Dual Cab" : "For Single Cab"}
                                        </span>
                                    </span>
                                </span>

                                <span className="form-check form-check-custom form-check-solid">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="signageOption"
                                        value={option}
                                        checked={selectedSignage === option}
                                        onChange={handleSignageChange}
                                    />
                                </span>
                            </label>
                        ))}
                    </div>
                    {/*end::Form Group */}
                </div>
            </div>
            {/*end::Step 4 */}
        </>
    );
};

export { Step4 };
